import React from 'react';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import {
  useAssignmentTypes,
  useClassGradingConfiguration,
  useGradingPeriods,
  useGridData,
} from '../queries';
import WeightingTableBody from './WeightingTableBody';
import Cell from '../grid/Cell';
import WeightingTotals from './WeightingTotals';
import { useFilters } from '../../../../stores/useGradebookStore';
import { getGradingPeriodId, getTotalsByGradingPeriod } from '../helpers';
import { TotalsByGradingPeriod } from '../types';
import { useScreenSizes } from '../../../../stores/useAppStore';
import { Link } from 'react-router-dom';
import { ALL_YEAR_GRADING_PERIOD } from '../constants';

const WeightingPage = () => {
  const { data: gradingPeriodConfigs } = useClassGradingConfiguration();
  const { data: assignmentTypes } = useAssignmentTypes();
  const { data: gridData } = useGridData();
  const { gradingPeriod: gradingPeriodFilter } = useFilters();
  const { data: gradingPeriods } = useGradingPeriods();
  const gradingPeriodId = getGradingPeriodId(gradingPeriodFilter, gradingPeriods);
  const { data } = useGridData();
  const assignments = data?.assignmentsObj;
  const { isMd: isMobile } = useScreenSizes();

  if (isMobile) {
    return (
      <div className="flex flex-col justify-center items-center h-[60vh]">
        <div className="text-2xl text-center">This page is not available on mobile devices.</div>
        <Link to={`/`}>
          <i className="nc-icon-glyph arrows-3_circle-left text-lg mr-2 leading-8" />
          <span className="leading-8">Back to Scores</span>
        </Link>
      </div>
    );
  }

  if (!gradingPeriodConfigs || !assignmentTypes || !gridData || !assignments || !gradingPeriods) {
    return <LoadingSpinner />;
  }

  if (!Object.keys(gradingPeriodConfigs).length) {
    return (
      <div className="flex justify-center items-center h-[60vh]">
        <div className="text-2xl text-center">
          No grading periods configurations have been created for this class.
        </div>
      </div>
    );
  }

  const classOverrides = 'bg-blue-6 w-32 border border-blue-5';
  const totalsByGradingPeriod: TotalsByGradingPeriod = getTotalsByGradingPeriod(assignments);

  return (
    <div className="md:overflow-auto max-md:w-full w-full flex min-h-[750px] h-[80vh] flex-col pb-10">
      {Object.entries(gradingPeriodConfigs)
        .filter(
          ([gradingPeriod, _periodConfig]) =>
            gradingPeriodId === ALL_YEAR_GRADING_PERIOD ||
            gradingPeriod === gradingPeriodFilter.toString()
        )
        .map(([gradingPeriodId, periodConfig]) => {
          const periodDescription = gradingPeriods.find(
            (period) => period.id === parseInt(gradingPeriodId)
          )?.description;
          return (
            <div
              className="w-40 mt-4 ml-10"
              key={gradingPeriodId}
            >
              <div className="text-lg font-semibold w-[692px] text-center mb-4">
                {periodDescription}
              </div>
              <table className="rounded-md">
                <thead>
                  <tr className="">
                    <Cell
                      classOverrides={
                        classOverrides.replace('w-32', 'min-w-max') + ' text-left px-3'
                      }
                    >
                      Assignment Types
                    </Cell>
                    <Cell classOverrides={classOverrides}>Weight</Cell>
                    <Cell classOverrides={classOverrides}>Drop Lowest</Cell>
                    <Cell classOverrides={classOverrides}>Count</Cell>
                    <Cell classOverrides={classOverrides}>Total Points</Cell>
                  </tr>
                </thead>
                <WeightingTotals
                  periodConfig={periodConfig}
                  totals={totalsByGradingPeriod[gradingPeriodId] || {}}
                />
                <WeightingTableBody
                  periodConfig={periodConfig}
                  assignmentTypes={assignmentTypes}
                  totals={totalsByGradingPeriod[gradingPeriodId] || {}}
                />
              </table>
            </div>
          );
        })}
    </div>
  );
};

export default WeightingPage;
