import { useMutation, useQueryClient } from '@tanstack/react-query';
import useGradebookStore, { useClassState, useFilters } from '../../../stores/useGradebookStore';
import { makeRequest, PORTALS_REQUEST_URL_PARAMS } from './common/request';
import produce from 'immer';
import {
  Assignment,
  EmailNotifications,
  UpdateObjectParams,
  File,
  AssignmentFormValues,
  Grade,
  GridData,
  ClassState,
  Student,
} from './types';
import { useNavigate } from 'react-router-dom';
import { getAssignmentIdFromUrl, initAssignmentsObject } from './helpers';
import { ASSIGNMENT_FIELDS_THAT_EFFECT_CLASS_GRADES, GradingMethodDescription } from './constants';
import { useGridData } from './queries';

const getGridDateQueryKey = (classState: ClassState) => [
  'gradebook',
  'class',
  classState.filters.gradingPeriod,
  classState.filters.assignmentType,
  classState.filters.sortAssignmentsByNewest,
  classState.filters.schoolYear,
];

export const useSendEmailNotifications = (gradeId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (types: EmailNotifications) => {
      return await sendEmailNotifications(types, gradeId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['gradebook', 'class'],
        });
        queryClient.invalidateQueries({
          queryKey: ['gradebook', 'assignmentFeedback', gradeId],
        });
      },
    }
  );
};

async function sendEmailNotifications(types: EmailNotifications, gradeId: number) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  const url = Routes.gradebook_send_email_notifications_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  const body = {
    notification_types: types,
    class_assignment_person_pk: gradeId,
  };

  return await makeRequest(url, 'PUT', authToken, body);
}

type AddFiles = {
  files: string[];
};
type ResponseData = {
  class_assignment_pk: string;
  files_added: File[];
};
export const useAddAssignmentFiles = () => {
  const queryClient = useQueryClient();
  const assignmentId = getAssignmentIdFromUrl();

  return useMutation(
    async (data: AddFiles) => {
      return await addAssignmentFiles(data, assignmentId);
    },
    {
      onSuccess: (response: ResponseData) => {
        if (response?.files_added) {
          queryClient.invalidateQueries({
            queryKey: ['gradebook', 'class'],
          });
        }
      },
    }
  );
};

async function addAssignmentFiles(data: AddFiles, assignmentId: number) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  const url = Routes.gradebook_add_assignment_file_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  const body = { ...data, class_assignment_pk: assignmentId, classId };

  return await makeRequest(url, 'POST', authToken, body);
}

type AddFeedback = {
  message: string;
  class_assignment_person_pk: number;
};
export const useAddAssignmentFeedback = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: AddFeedback) => {
      return await addAssignmentFeedback(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['gradebook', 'allAssignmentFeedback'],
        });
      },
    }
  );
};

async function addAssignmentFeedback(data: AddFeedback) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  const url = Routes.gradebook_add_assignment_feedback_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  const body = { ...data, classId };

  return await makeRequest(url, 'POST', authToken, body);
}

export const useDeleteAssignment = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const classState = useClassState();
  const assignmentId = getAssignmentIdFromUrl();

  return useMutation(
    async () => {
      return await deleteAssignment(assignmentId);
    },
    {
      onSuccess: async (response: { data: { deleted_id: string } }) => {
        if (response?.data?.deleted_id) {
          navigate(`/`);
          queryClient.invalidateQueries({
            queryKey: [
              'gradebook',
              'class',
              classState.filters.gradingPeriod,
              classState.filters.assignmentType,
            ],
          });
        }
      },
    }
  );
};

async function deleteAssignment(assignmentId: number) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  const url = Routes.gradebook_delete_assignment_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  const body = { assignment_id: assignmentId, classId };

  return await makeRequest(url, 'DELETE', authToken, body);
}

type DeleteFeedback = {
  feedback_id: number;
};
export const useDeleteAssignmentFeedback = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: DeleteFeedback) => {
      return await deleteAssignmentFeedback(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['gradebook', 'allAssignmentFeedback'],
        });
      },
    }
  );
};

async function deleteAssignmentFeedback(data: DeleteFeedback) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  const url = Routes.gradebook_delete_assignment_feedback_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  const body = { ...data, classId };

  return await makeRequest(url, 'DELETE', authToken, body);
}

type DeleteAssignmentFile = {
  file_pk: string;
};
export const useDeleteAssignmentFile = () => {
  const queryClient = useQueryClient();
  const assignmentId = getAssignmentIdFromUrl();

  return useMutation(
    async (data: DeleteAssignmentFile) => {
      return await deleteAssignmentFile(data, assignmentId);
    },
    {
      onSuccess: (response: { record_id: string; file_Pk: string }) => {
        if (response?.record_id && response.record_id !== '0') {
          queryClient.invalidateQueries({
            queryKey: ['gradebook', 'class'],
          });
        }
      },
    }
  );
};

async function deleteAssignmentFile(data: DeleteAssignmentFile, assignmentId: number) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  const url = Routes.gradebook_delete_assignment_file_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  const body = { ...data, record_id: assignmentId, classId };

  return await makeRequest(url, 'DELETE', authToken, body);
}

export const useAddAssignment = () => {
  const { gradingPeriod } = useFilters();
  const queryClient = useQueryClient();
  const setGradingPeriod = useGradebookStore.getState().actions.setGradingPeriod;
  const navigate = useNavigate();

  return useMutation(
    async (data: { form_values: AssignmentFormValues }) => {
      return await addAssignment(data);
    },
    {
      onSuccess: async (response: { data: Assignment }) => {
        if (response?.data?.class_assignment_pk) {
          let newPeriod = gradingPeriod;
          if (response.data.grading_period !== gradingPeriod) {
            newPeriod = response.data.grading_period;
            setGradingPeriod(newPeriod);
          }
          queryClient.invalidateQueries({
            queryKey: ['gradebook', 'class'],
          });
          navigate(`/${response.data.class_assignment_pk}/assignment/`);
        }
      },
    }
  );
};

async function addAssignment(data: { form_values: AssignmentFormValues }) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  if (data.form_values.grading_method === GradingMethodDescription.LETTER) {
    // use table defaults for letter grading, not the configuration values
    delete data.form_values.maximum_score;
    delete data.form_values.weight;
  }

  const url = Routes.gradebook_add_assignment_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  const body = { ...data, classId };

  return await makeRequest(url, 'POST', authToken, body);
}

const updateAssignmentQueryData = (assignment: Assignment, queryClient, classState: ClassState) => {
  const queryKey = getGridDateQueryKey(classState);
  queryClient.setQueryData(queryKey, (currentState: GridData) =>
    produce(currentState, (newState: GridData) => {
      newState.assignments = newState.assignments.map((record) => {
        if (record.class_assignment_pk === assignment.class_assignment_pk) {
          return { ...assignment };
        }
        return record;
      });

      newState.assignmentsObj = initAssignmentsObject(
        newState.assignments,
        classState.today,
        classState.filters.sortAssignmentsByNewest
      );
    })
  );
};

export const useUpdateAssignment = (assignmentId: number) => {
  const setGradingPeriod = useGradebookStore.getState().actions.setGradingPeriod;
  const queryClient = useQueryClient();
  const classState = useClassState();

  return useMutation(
    async (params: { field: string; value: any }) => {
      return await updateAssignment(assignmentId, params.field, params.value);
    },
    {
      onSuccess: (response: { data: Partial<UpdateObjectParams> }) => {
        if (ASSIGNMENT_FIELDS_THAT_EFFECT_CLASS_GRADES.includes(response.data.updated_field)) {
          queryClient.invalidateQueries({
            queryKey: ['gradebook', 'classGrades', classState.filters.gradingPeriod],
          });
        }

        const updatedAssignment = response.data.updated_object as Assignment;
        if (
          response.data.updated_field === 'due_date' &&
          classState.filters.gradingPeriod !== updatedAssignment.grading_period
        ) {
          setGradingPeriod(updatedAssignment.grading_period);
          queryClient.invalidateQueries({
            queryKey: ['gradebook', 'class'],
          });
        }

        updateAssignmentQueryData(
          response.data.updated_object as Assignment,
          queryClient,
          classState
        );
      },
    }
  );
};

async function updateAssignment(assignmentId: number, field: string, value: any) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  const url = Routes.gradebook_update_assignment_record_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  const body = {
    assignment_id: assignmentId,
    field,
    value,
  };

  return await makeRequest(url, 'PUT', authToken, body);
}

export const useBatchUpdateGradeRecord = () => {
  const queryClient = useQueryClient();
  const assignmentId = getAssignmentIdFromUrl();
  const { data } = useGridData();
  const studentIds = data.students.map((student: Student) => student.person_pk);

  return useMutation(
    async (batchUpdates: any) => {
      return await batchUpdateGradeRecord(assignmentId, batchUpdates, studentIds);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['gradebook', 'class'],
        });
      },
    }
  );
};

async function batchUpdateGradeRecord(
  assignmentId: number,
  batchUpdates: any,
  studentIds: number[]
) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  const url = Routes.gradebook_update_grade_records_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
    student_ids: studentIds,
  });

  const body = {
    assignment_id: assignmentId,
    requested_updates: batchUpdates,
  };

  return await makeRequest(url, 'PUT', authToken, body);
}

export const useUpdateGradeRecord = (studentId: number, field: string) => {
  const queryClient = useQueryClient();
  const classState = useClassState();
  const assignmentId = getAssignmentIdFromUrl();

  return useMutation(
    async (newValue: string | number) => {
      return await updateGradeRecord(studentId, assignmentId, field, newValue);
    },
    {
      onSuccess: (response: { data: UpdateObjectParams }) => {
        if (response.data?.updated_object) {
          const {
            assignment_id: assignmentId,
            student_id: studentId,
            updated_object: updatedGrade,
          } = response.data;
          const queryKey = getGridDateQueryKey(classState);
          // Set the updated grade in the cached query data
          queryClient.setQueryData(queryKey, (currentState) =>
            produce(currentState, (newState: GridData) => {
              newState.assignment_grades_by_student[`${studentId}-${assignmentId}`] =
                updatedGrade as Grade;
            })
          );

          // Refetch the class grades query so that the server can
          // recalculate the class grade based on the updated assignment grade.
          queryClient.invalidateQueries({
            queryKey: ['gradebook', 'classGrades', classState.filters.gradingPeriod],
          });
        }
      },
    }
  );
};

async function updateGradeRecord(
  studentId: number,
  assignmentId: number,
  field: string,
  value: string | number
) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  const url = Routes.gradebook_update_grade_record_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  const body = {
    student_id: studentId,
    assignment_id: assignmentId,
    field,
    value,
  };

  return await makeRequest(url, 'PUT', authToken, body);
}

export const useUpdateClassGradingConfiguration = (recordId: number, field: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (newValue: string | number | boolean) => {
      return await updateClassGradingConfiguration(recordId, field, newValue);
    },
    {
      onSuccess: (response) => {
        if (response?.message === 'success') {
          queryClient.invalidateQueries({
            queryKey: ['gradebook', 'classGradingConfiguration'],
          });
        } else {
          throw new Error(response?.message);
        }
      },
    }
  );
};

async function updateClassGradingConfiguration(
  recordId: number,
  field: string,
  value: string | number | boolean
) {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;

  const url = Routes.gradebook_update_class_grading_configuration_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  const body = {
    record_id: recordId,
    field,
    value,
  };

  return await makeRequest(url, 'PUT', authToken, body);
}
