import create from 'zustand';
import produce from 'immer';
import {
  Actions,
  ClassState,
  FilterState,
  GradebookConfigurations,
  GradebookState,
} from '../components/system/gradebook/types';
import {
  ALL_YEAR_GRADING_PERIOD,
  DEFAULT_CONFIGURATIONS,
} from '../components/system/gradebook/constants';

const useGradebookStore = create<GradebookState>((set) => ({
  classState: {
    authToken: null,
    classId: null,
    configurations: DEFAULT_CONFIGURATIONS,
    teacherPhotoUrl: '',
    className: '',
    startDate: '',
    endDate: '',
    today: '',
    uploaderOptions: {},
    multiYearClassData: {
      begin_year: 0,
      end_year: 0,
      years: [],
    },

    filters: {
      gradingPeriod: ALL_YEAR_GRADING_PERIOD,
      assignmentType: 0,
      sortAssignmentsByNewest: true,
    },
  },

  actions: {
    setGradingPeriod: (newValue) =>
      set(
        produce((state) => {
          state.classState.filters.gradingPeriod = newValue;
        })
      ),
    setSchoolYear: (newValue) =>
      set(
        produce((state) => {
          state.classState.filters.schoolYear = newValue;
        })
      ),
    setAssignmentType: (newValue) =>
      set(
        produce((state) => {
          state.classState.filters.assignmentType = newValue;
        })
      ),
    toggleSortAssignmentsByNewest: () =>
      set(
        produce((state) => {
          state.classState.filters.sortAssignmentsByNewest =
            !state.classState.filters.sortAssignmentsByNewest;
        })
      ),
    initialize: (data) =>
      set(
        produce((state) => {
          state.classState = {
            authToken: data.authToken,
            classId: data.classId,
            configurations: data.configurations,
            teacherPhotoUrl: data.teacherPhotoUrl,
            className: data.className,
            startDate: data.startDate,
            endDate: data.endDate,
            today: data.today,
            uploaderOptions: data.uploaderOptions,
            multiYearClassData: data.multiYearClassData,
          };
          state.classState.filters = {
            ...state.classState.filters,
            assignmentType: 0,
            gradingPeriod: data.filters.gradingPeriod,
            sortAssignmentsByNewest: data.filters.sortAssignmentsByNewest,
            schoolYear: data.filters.schoolYear,
          };
        })
      ),
  },
}));

export const useGradebookActions = (): Actions => useGradebookStore((state) => state.actions);

export const useConfigurations = (): GradebookConfigurations =>
  useGradebookStore((state) => state.classState.configurations);

export const useClassState = (): ClassState => useGradebookStore((state) => state.classState);

export const useFilters = (): FilterState => useGradebookStore((state) => state.classState.filters);

export default useGradebookStore;
