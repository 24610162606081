import { useQuery } from '@tanstack/react-query';
import useGradebookStore, { useClassState } from '../../../stores/useGradebookStore';
import { makeRequest, PORTALS_REQUEST_URL_PARAMS } from './common/request';
import {
  assignmentNotFound,
  getAssignmentIdFromUrl,
  initAssignmentsObject,
  userIsNotAuthorized,
} from './helpers';
import {
  Assignment,
  AssignmentFeedback,
  AssignmentFeedbackCondensed,
  AssignmentType,
  ClassGrades,
  ClassGradingConfiguration,
  ClassState,
  ConversionScalesResponseObject,
  GradingMethod,
  GradingPeriod,
  GridData,
} from './types';
import { queryClient } from './Router';
import { ASSIGNMENT_NOT_FOUND_ERROR } from './constants';
import { useNavigate } from 'react-router-dom';

export const useGridData = () => {
  const assignmentId = getAssignmentIdFromUrl();
  const classState = useClassState();
  const navigate = useNavigate();

  return useQuery<GridData, Error>({
    queryKey: [
      'gradebook',
      'class',
      classState.filters.gradingPeriod,
      classState.filters.assignmentType,
      classState.filters.sortAssignmentsByNewest,
      classState.filters.schoolYear,
    ],
    queryFn: async () => {
      try {
        return await fetchGridData(classState, assignmentId);
      } catch (error) {
        if (assignmentNotFound(error)) {
          navigate('/', { state: { bannerMessage: ASSIGNMENT_NOT_FOUND_ERROR } });
        } else if (userIsNotAuthorized(error)) {
          navigate('/', { state: { notAuthorized: true } });
        }
        throw new Error(error);
      }
    },
    retry: false,
  });
};

export const useAssignment = (): Assignment => {
  const assignmentId = getAssignmentIdFromUrl();

  const { data } = useGridData();
  return data?.assignmentsObj[assignmentId];
};

export const useStudentGrade = (studentId: number, assignmentId?: number) => {
  assignmentId = assignmentId || getAssignmentIdFromUrl();

  const { data } = useGridData();
  return data?.assignment_grades_by_student[`${studentId}-${assignmentId}`];
};

export const useAssignmentFiles = () => {
  const assignmentId = getAssignmentIdFromUrl();

  const { data } = useGridData();
  return data?.assignment_files[assignmentId];
};

async function fetchGridData(classState: ClassState, assignmentId?: number): Promise<GridData> {
  const setGradingPeriod = useGradebookStore.getState().actions.setGradingPeriod;
  const setSchoolYear = useGradebookStore.getState().actions.setSchoolYear;
  const {
    filters: { gradingPeriod, assignmentType, sortAssignmentsByNewest, schoolYear },
    authToken,
    classId,
    today,
  } = classState;

  const url = Routes.gradebook_grid_data_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
    grading_period: gradingPeriod,
    assignment_type: assignmentType,
    assignment_id: assignmentId,
    sort_by_newest: sortAssignmentsByNewest,
    school_year: schoolYear,
  });

  const response = await makeRequest(url, 'GET', authToken);
  if (response.grading_period !== gradingPeriod) {
    // grading period has changed
    setGradingPeriod(response.grading_period);
  }
  if (response.school_year !== schoolYear) {
    // school_year has changed
    setSchoolYear(response.school_year);
  }

  response.assignmentsObj = initAssignmentsObject(
    response.assignments,
    today,
    sortAssignmentsByNewest
  );
  return response;
}

export const useClassGradingConfiguration = () => {
  const classState = useClassState();

  return useQuery<ClassGradingConfiguration, Error>({
    queryKey: ['gradebook', 'classGradingConfiguration'],
    queryFn: async () => {
      try {
        return await fetchClassGradingConfiguration(classState);
      } catch (error) {
        throw new Error(error);
      }
    },
    retry: false,
  });
};

async function fetchClassGradingConfiguration(
  classState: ClassState
): Promise<ClassGradingConfiguration> {
  const { authToken, classId } = classState;

  const url = Routes.gradebook_class_grading_configuration_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });

  return await makeRequest(url, 'GET', authToken);
}

export const useClassGrades = () => {
  const classState = useClassState();
  const navigate = useNavigate();

  return useQuery<ClassGrades, Error>({
    queryKey: ['gradebook', 'classGrades', classState.filters.gradingPeriod],
    queryFn: async () => {
      try {
        return await fetchClassGrades(classState);
      } catch (error) {
        if (userIsNotAuthorized(error)) {
          navigate('/', { state: { notAuthorized: true } });
        }
        throw new Error(error);
      }
    },
    retry: false,
  });
};

async function fetchClassGrades(classState: ClassState): Promise<ClassGrades> {
  const {
    filters: { gradingPeriod },
    authToken,
    classId,
    configurations: {
      predicted_grade_grading_period,
      predicted_grade_format,
      display_predicted_grade_column,
      display_calculated_grade_column,
    },
  } = classState;

  const url = Routes.gradebook_class_grades_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
    grading_period: gradingPeriod,
    predicted_grade_format,
    predicted_grade_grading_period,
    display_calculated_grade: display_calculated_grade_column,
    display_predicted_grade: display_predicted_grade_column,
  });

  return await makeRequest(url, 'GET', authToken);
}

export const useGradeConversionScales = () => {
  return useQuery<ConversionScalesResponseObject, Error>({
    queryKey: ['gradebook', 'gradingConversionScales'],
    queryFn: () => fetchGradeConversionScales(),
    retry: false,
  });
};

async function fetchGradeConversionScales(): Promise<ConversionScalesResponseObject> {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;
  const url = Routes.gradebook_grade_conversion_scales_data_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });
  return await makeRequest(url, 'GET', authToken);
}

export const useGradingPeriods = () => {
  const {
    filters: { schoolYear },
  } = useClassState();

  return useQuery<GradingPeriod[], Error>({
    queryKey: ['gradebook', 'gradingPeriods', schoolYear],
    queryFn: () => fetchGradingPeriods(),
    retry: false,
  });
};

async function fetchGradingPeriods(): Promise<GradingPeriod[]> {
  const classState = useGradebookStore.getState().classState;
  const {
    filters: { schoolYear },
    authToken,
    classId,
  } = classState;

  const url = Routes.gradebook_grading_periods_data_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
    school_year: schoolYear,
  });
  return await makeRequest(url, 'GET', authToken);
}

export const useAssignmentTypes = () =>
  useQuery<AssignmentType[], Error>({
    queryKey: ['gradebook', 'assignmentTypes'],
    queryFn: () => fetchAssignmentTypes(),
    retry: false,
  });

async function fetchAssignmentTypes(): Promise<AssignmentType[]> {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;
  const url = Routes.gradebook_assignment_types_data_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });
  return await makeRequest(url, 'GET', authToken);
}

export const useGradingMethods = () =>
  useQuery<GradingMethod[], Error>({
    queryKey: ['gradebook', 'gradingMethods'],
    queryFn: () => fetchGradingMethods(),
  });

async function fetchGradingMethods(): Promise<GradingMethod[]> {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;
  const url = Routes.gradebook_grading_methods_data_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });
  return await makeRequest(url, 'GET', authToken);
}

export const useAllAssignmentFeedback = () => {
  return useQuery<AssignmentFeedbackCondensed, Error>({
    queryKey: ['gradebook', 'allAssignmentFeedback'],
    queryFn: () => fetchAllAssignmentFeedback(),
    retry: false,
  });
};

async function fetchAllAssignmentFeedback(): Promise<AssignmentFeedbackCondensed> {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;
  const url = Routes.gradebook_all_assignment_feedback_data_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
  });
  return await makeRequest(url, 'GET', authToken);
}

export const useAssignmentFeedback = (assignmentPersonId: number) => {
  return useQuery<AssignmentFeedback, Error>({
    queryKey: ['gradebook', 'assignmentFeedback', assignmentPersonId],
    queryFn: () => fetchAssignmentFeedback(assignmentPersonId),
    retry: false,
  });
};

async function fetchAssignmentFeedback(assignmentPersonId: number): Promise<AssignmentFeedback> {
  const classId = useGradebookStore.getState().classState.classId;
  const authToken = useGradebookStore.getState().classState.authToken;
  const url = Routes.gradebook_assignment_feedback_data_path({
    ...PORTALS_REQUEST_URL_PARAMS,
    class_id: classId,
    class_assignment_person_pk: assignmentPersonId,
  });
  return await makeRequest(url, 'GET', authToken);
}
