import React from 'react';
import { useGradingPeriods } from '../queries';
import ReactSelect from 'react-select';
import { useFilters, useGradebookActions } from '../../../../stores/useGradebookStore';

const GradingPeriodsDropdown = () => {
  const { data: gradingPeriods, isLoading } = useGradingPeriods();
  const { setGradingPeriod } = useGradebookActions();
  const { gradingPeriod } = useFilters();

  let options = [];
  let selectedPeriod = null;
  if (!isLoading) {
    options = (gradingPeriods || []).map((period) => {
      return { value: period.id, label: period.description };
    });
    selectedPeriod = options.find((option) => gradingPeriod === option.value);
  }

  return (
    <div
      className="w-32"
      data-testid="grading-period-dropdown"
    >
      <ReactSelect
        value={selectedPeriod}
        options={options}
        onChange={(newSelection) => setGradingPeriod(newSelection.value)}
        isSearchable={false}
        maxMenuHeight={200}
        isDisabled={false}
        isLoading={isLoading}
        menuPlacement="auto"
        styles={{
          control: (base) => ({
            ...base,
            height: '32px',
            minHeight: '32px',
          }),
          indicatorsContainer: (base) => ({
            ...base,
            height: '32px',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          singleValue: (base) => ({
            ...base,
            color: '#555555',
          }),
        }}
      />
    </div>
  );
};

export default GradingPeriodsDropdown;
