import React from 'react';
import ReactSelect from 'react-select';
import {
  useClassState,
  useFilters,
  useGradebookActions,
} from '../../../../stores/useGradebookStore';
import { queryClient } from '../Router';

const ClassYearsDropdown = () => {
  const { multiYearClassData } = useClassState();
  const { setSchoolYear } = useGradebookActions();
  const { schoolYear } = useFilters();
  const { begin_year: beginYear, end_year: endYear, years } = multiYearClassData;

  let selectedPeriod = null;
  const options = [];
  for (let year = beginYear; year <= endYear; year++) {
    const yearDescription = years.find((yearObj) => yearObj.id === year)?.description || year;
    options.push({ value: year, label: yearDescription });
  }
  selectedPeriod = options.find((option) => schoolYear === option.value);

  const handleChange = (selectedOption) => {
    setSchoolYear(selectedOption.value);
    queryClient.invalidateQueries(['gradebook', 'class']);
  };

  return (
    <div
      className="w-20"
      data-testid="grading-period-dropdown"
    >
      <ReactSelect
        value={selectedPeriod}
        options={options}
        onChange={handleChange}
        isSearchable={false}
        maxMenuHeight={200}
        isDisabled={false}
        menuPlacement="auto"
        styles={{
          control: (base) => ({
            ...base,
            height: '32px',
            minHeight: '32px',
          }),
          indicatorsContainer: (base) => ({
            ...base,
            height: '32px',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          singleValue: (base) => ({
            ...base,
            color: '#555555',
          }),
        }}
      />
    </div>
  );
};

export default ClassYearsDropdown;
