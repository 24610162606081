import { useParams } from 'react-router-dom';
import {
  GradingMethodDescription,
  DEFAULT_ERROR_MESSAGE,
  PARSE_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  USER_EDIT_ERROR,
  CompletionStatus,
  STATUS_THAT_COUNTS_ZERO_GRADES,
  ALL_YEAR_GRADING_PERIOD,
} from './constants';
import {
  Assignment,
  ClassGrade,
  ConversionLevel,
  ConversionScale,
  Grade,
  GradingPeriod,
  Student,
  TotalsByGradingPeriod,
  ZgStatus,
} from './types';
import moment from 'moment';
import * as Yup from 'yup';

export const getBasePath = (): string => {
  // converts:
  // '/vc.client.faculty@vcdemo_client/faculty/class/5412/gradebook/123/assignment'
  // into:
  // '/vc.client.faculty@vcdemo_client/faculty/class/5412/gradebook'

  // This is needed because the base path is variable, depending on the user
  // and we want the router to ignore that part
  const path = window.location.pathname;
  return path.split('/').splice(0, 6).join('/');
};

export const getDisplayDate = (date: string): string => {
  return moment(date, PARSE_DATE_FORMAT).format(SHORT_DATE_FORMAT);
};

export const initAssignmentsObject = (
  assignments: Assignment[],
  today: string,
  sortAssignmentsByNewest: boolean
): Record<string, Assignment> => {
  const assignmentObj = {};

  for (let x = 0; x < assignments.length; ++x) {
    const assignment = assignments[x];
    assignment.nextAssignmentId =
      x === assignments.length - 1 ? null : assignments[x + 1].class_assignment_pk;
    assignment.prevAssignmentId = x === 0 ? null : assignments[x - 1].class_assignment_pk;
    assignment.todayLine = showTodayLine(
      assignment,
      today,
      assignments,
      sortAssignmentsByNewest,
      x
    );
    assignmentObj[assignment.class_assignment_pk] = assignment;
  }

  return assignmentObj;
};

export const getCellDateColor = (today: string, dueDate: string) => {
  const todayMoment = moment(today, PARSE_DATE_FORMAT);
  const dueDateMoment = moment(dueDate, PARSE_DATE_FORMAT);
  if (todayMoment.isSame(dueDateMoment, 'days')) {
    return 'bg-yellow-6';
  } else {
    return 'bg-neutral-6';
  }
};

export const getStudentColumnWidth = (hidePhotos: boolean) => {
  return hidePhotos ? 'w-40' : 'w-52';
};

export const getGradeDisplay = (
  gradeRecord: Partial<Grade>,
  gradingMethod: number,
  scales: ConversionLevel[],
  isCalcGrade?: boolean
): { grade: string; isLabel: boolean } => {
  if (!gradeRecord || Object.keys(gradeRecord).length === 0) return { grade: '', isLabel: false };
  const { completion_status, score } = gradeRecord;

  if (gradingMethod === GradingMethodDescription.LETTER) {
    return getLetterGradeDisplay(gradeRecord, score, scales);
  }

  const label =
    getGradeLabel(completion_status) || getNoScoreLabel(score, completion_status, isCalcGrade);
  return {
    grade: label || getFloatDisplay(score),
    isLabel: Boolean(label),
  };
};

export const getLetterGradeDisplay = (
  gradeRecord: Partial<Grade>,
  score: string,
  scales: ConversionLevel[]
): { grade: string; isLabel: boolean } => {
  const label = getGradeLabel(gradeRecord.completion_status);
  return {
    grade: label || getLetterGrade(score, scales),
    isLabel: Boolean(label),
  };
};

const getGradeLabel = (completionStatus: number): string => {
  // show a label instead of the score for these statuses
  switch (completionStatus) {
    case CompletionStatus.NOT_TURNED_IN:
      return 'NTI';
    case CompletionStatus.NOT_REQUIRED_TO_COMPLETE:
      return 'NREQ';
    case CompletionStatus.PENDING:
      return '-';
    case CompletionStatus.INCOMPLETE:
      return 'INC';
    case CompletionStatus.COMPLETE_NO_CREDIT:
      return 'NC';
    case CompletionStatus.TURNED_IN_NOT_GRADED:
      return '\u2713';
    default:
      return null;
  }
};

const getNoScoreLabel = (score: string, completionStatus: number, isCalcGrade: boolean): string => {
  // show a label for these statuses when there is no score
  if (parseFloat(score) > 0) return null;
  switch (completionStatus) {
    case CompletionStatus.COMPLETE:
      return !isCalcGrade && 'Complete';
    case CompletionStatus.LATE:
      return 'LTE';
    default:
      null;
  }
};

export const getFileTypeIcon = (description) => {
  if (!description) return '';
  const fileExtension = description.split('.').pop().toLowerCase();
  switch (fileExtension) {
    case 'png':
    case 'img':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return 'files_image';
    case 'zip':
      return 'files_zip-55';
    case 'wav':
    case 'mp3':
    case 'aac':
    case 'wma':
    case 'flac':
      return 'files_audio';
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'wmv':
      return 'media-1_play-68';
    case 'doc':
    case 'docx':
      return 'files_single-folded-content';
    default:
      return 'files_single-folded';
  }
};

// source: https://stackoverflow.com/questions/635022/calculating-contrasting-colours-in-javascript
export const contrastingColor = (color: string) => {
  if (color.startsWith('#')) {
    color = color.slice(1);
  }
  return luma(color) >= 165 ? '#000000' : '#ffffff';
};
function luma(color) {
  // color can be a hx string or an array of RGB values 0-255
  const rgb = hexToRGBArray(color);
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
}
function hexToRGBArray(color) {
  if (color.length === 3) {
    color =
      color.charAt(0) +
      color.charAt(0) +
      color.charAt(1) +
      color.charAt(1) +
      color.charAt(2) +
      color.charAt(2);
  } else if (color.length !== 6) {
    return '#ffffff'; // invalid color, default to white
  }

  const rgb = [];
  for (let i = 0; i <= 2; i++) {
    rgb[i] = parseInt(color.substr(i * 2, 2), 16);
  }
  return rgb;
}

export const AssignmentSchema = Yup.object().shape({
  description: Yup.string().max(256, '256 character limit exceeded').required('Required'),
  teacher_notes: Yup.string().max(1500, '1500 character limit exceeded'),
  assignment_date: Yup.date().required('Required'),
  due_date: Yup.date().required('Required'),
  maximum_score: Yup.lazy((value) =>
    value === '' ? Yup.string() : Yup.number().typeError('Must be a number').required('Required')
  ),
  weight: Yup.lazy((value) =>
    value === '' ? Yup.string() : Yup.number().typeError('Must be a number').required('Required')
  ),
});

export const userIsNotAuthorized = (error) => error.status === 401;
export const assignmentNotFound = (error) => error.status === 404;

export const getErrorMessage = (error) => {
  if (error.message) {
    if (error.message.startsWith('TinyTds::Error:')) {
      // clean up message that comes from trigger
      return error.message.split('TinyTds::Error:')[1];
    }
    return error.message;
  }
  return userIsNotAuthorized(error) ? USER_EDIT_ERROR : DEFAULT_ERROR_MESSAGE;
};

export const getFloatDisplay = (grade: string): string => {
  return (Math.round((parseFloat(grade) + Number.EPSILON) * 100) / 100).toString();
};

export const showTodayLine = (
  assignment: Partial<Assignment>,
  today: string,
  assignments: Partial<Assignment>[],
  isDescending: boolean,
  index: number
) => {
  const todayMoment = moment(today, PARSE_DATE_FORMAT);
  const assignmentMoment = moment(assignment.due_date, PARSE_DATE_FORMAT);
  const previousAssignmentMoment =
    index > 0 ? moment(assignments[index - 1]?.due_date, PARSE_DATE_FORMAT) : null;

  const isTodayAfter = todayMoment.isAfter(assignmentMoment);
  const isTodayBeforeOrSame = todayMoment.isSameOrBefore(assignmentMoment);
  const isTodayAfterPrevious =
    previousAssignmentMoment && todayMoment.isAfter(previousAssignmentMoment);
  const isTodayBeforeOrSamePrevious =
    previousAssignmentMoment && todayMoment.isSameOrBefore(previousAssignmentMoment);

  const showLineAtStart =
    index === 0 && ((isDescending && isTodayAfter) || (!isDescending && isTodayBeforeOrSame));

  const showLineInBetween =
    index > 0 &&
    ((isDescending && isTodayAfter && isTodayBeforeOrSamePrevious) ||
      (!isDescending && isTodayBeforeOrSame && isTodayAfterPrevious));

  const showLineAtEnd =
    index === assignments.length - 1 &&
    ((isDescending && isTodayBeforeOrSame) || (!isDescending && isTodayAfter));

  return {
    showLine: showLineAtStart || showLineInBetween,
    showLineAfter: showLineAtEnd,
  };
};

export const getScaleLevel = (grade: string, scaleLevels: ConversionLevel[]): ConversionLevel => {
  if (!scaleLevels) return null;
  const gradeValue = parseFloat(grade);
  const scaleLevel = scaleLevels.find((scaleLevel) => gradeValue >= scaleLevel.minimum_value);
  return scaleLevel || scaleLevels[scaleLevels.length - 1];
};

export const getScaleDescription = (scales: ConversionScale[], selectedScale: number): string => {
  if (!scales) return '';
  const scale = scales.find((scale) => scale.id === selectedScale);
  if (!scale?.zg_grade_conversion_scale_levels) return '';

  return scale.zg_grade_conversion_scale_levels
    .map((level) => {
      return `${level.letter} = ${level.stored_value}`;
    })
    .join('\n');
};

export const getLetterGrade = (grade: string, scaleLevels: ConversionLevel[]): string => {
  return getScaleLevel(grade, scaleLevels)?.letter ?? '-';
};

export const getTotalsByGradingPeriod = (
  assignments: Record<string, Assignment>
): TotalsByGradingPeriod => {
  return Object.values(assignments).reduce((acc, assignment) => {
    const assignmentType = assignment.assignment_type;
    const gradingPeriod = assignment.grading_period;

    if (!acc[gradingPeriod]) {
      acc[gradingPeriod] = {};
    }
    if (!acc[gradingPeriod][assignmentType]) {
      acc[gradingPeriod][assignmentType] = { count: 0, weight: 0 };
    }

    acc[gradingPeriod][assignmentType].weight += assignment.weight;
    acc[gradingPeriod][assignmentType].count += 1;
    return acc;
  }, {} as TotalsByGradingPeriod);
};

export const getNextRowStudentId = (currentRowId: number, students: Student[]) => {
  if (!students?.length) return null;
  if (!currentRowId) return students[0]?.person_fk;

  const currentIndex = students.findIndex((student) => student.person_fk === currentRowId);
  const nextIndex = currentIndex + 1;
  return students[nextIndex] ? students[nextIndex].person_fk : null;
};

export const isFailingGrade = (
  scoreDisplayString: string,
  maxScore: number,
  minimumPassingGrade: string
): boolean => {
  if (
    !minimumPassingGrade ||
    maxScore === 0 ||
    !scoreDisplayString ||
    isNaN(parseFloat(scoreDisplayString))
  ) {
    return false;
  }

  const percentageScore = (parseFloat(scoreDisplayString) / maxScore) * 100;
  return percentageScore < parseFloat(minimumPassingGrade);
};

export const getAssignmentIdFromUrl = (): number => {
  // this function needs to be called from a ReactRouter context
  const { assignmentId } = useParams();

  // assignmentId in url is a string, so we need to convert it to a number
  // and check if it is a valid number, could be 'new' if creating a new assignment
  if (!assignmentId || Number.isNaN(Number(assignmentId))) return null;

  return parseInt(assignmentId);
};

export const isGradingPeriodChange = (
  date: moment.Moment,
  currentGradingPeriod: number,
  gradingPeriods: ZgStatus[]
) => {
  if (!currentGradingPeriod || !gradingPeriods) return false;

  const gradingPeriod = gradingPeriods.find((gp) => gp.id === currentGradingPeriod);
  if (!gradingPeriod) return false;

  const { begin_date: gradingPeriodStartDate, end_date: gradingPeriodEndDate } = gradingPeriod;
  if (date.isBetween(gradingPeriodStartDate, gradingPeriodEndDate, 'day', '[]')) return false;

  return gradingPeriods.some((gp) => date.isBetween(gp.begin_date, gp.end_date, 'day', '[]'));
};

export const hasGrade = (gradeRecord: Grade): boolean => {
  if (!gradeRecord) return false;
  const hasStatus = STATUS_THAT_COUNTS_ZERO_GRADES.has(gradeRecord.completion_status);
  const hasGrade = parseFloat(gradeRecord.score) !== 0;
  return hasStatus || hasGrade;
};

export const getClassGradeDisplay = (gradeRecord: ClassGrade, gradingPeriodId: number) => {
  if (gradingPeriodId === ALL_YEAR_GRADING_PERIOD) {
    return 'N/A';
  }

  if (gradeRecord?.calculated_grade) {
    return getFloatDisplay(gradeRecord.calculated_grade);
  }
  return '-';
};

export const getPredictedGradeDisplay = (gradeRecord: ClassGrade, predictedGradeFormat: string) => {
  const grade = gradeRecord?.predicted_grade;
  if (!grade || grade === '0.0') {
    return '-';
  }

  if (predictedGradeFormat === 'letter') {
    return grade;
  }
  return getFloatDisplay(grade);
};

export const getGradingPeriodId = (gradingPeriod: number, gradingPeriods: GradingPeriod[] = []) => {
  return gradingPeriods?.find((period) => period.id === gradingPeriod)?.grading_period_id;
};
