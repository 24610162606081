import React from 'react';

import { formatMonetaryValue } from 'components/shared/utils';
import useAsyncRequest from 'components/shared/hooks/useAsyncRequest';
import AuthTokenContext from 'components/shared/AuthTokenContext';

import EditPurchaseRequestItem from './EditPurchaseRequestItem';

const PurchaseRequestItemEditButton = (props) => {
  return (
    <button
      className="action-button vx-button vx-button--neutral"
      onClick={props.onClick}
    >
      Edit
    </button>
  );
};

const PurchaseRequestItemDeleteButton = (props) => {
  return (
    <button
      className="action-button vx-button vx-button--danger"
      onClick={props.onClick}
    >
      Delete
    </button>
  );
};

// prettier-ignore
function PurchaseRequestItem(props) {
  const {
    data,
    reloadItems,
    glAccounts,
    taxTypes,
    isDisabled,
    isPosted,
  } = props;

  const authToken = React.useContext(AuthTokenContext);

  const [isVisible, setIsVisible] = React.useState(true);
  const [showEditItem, setShowEditItem] = React.useState(false);

  /***************************************************************************************************
  ** Edit
  ***************************************************************************************************/

  const handleClickEditButton = React.useCallback((event) => {
    event.preventDefault();

    setShowEditItem(true);
  }, []);

  /***************************************************************************************************
  ** Delete
  ***************************************************************************************************/

  const destroyPurchaseRequestItemRequest = useAsyncRequest({
    pathName: 'destroy_purchase_request_item_path',
    params: {
      purchase_request_id: data.purchase_request_id,
      purchase_request_item_id: data.id,
    },
    method: 'DELETE',
    authorizationToken: authToken,
  });

  const handleClickDeleteButton = React.useCallback((event) => {
    event.preventDefault();

    Swal.fire({
      title: 'Are you sure you want to delete this item?',
      text: 'This action cannot be undone.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#43B664',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (!result.value) return;

      destroyPurchaseRequestItemRequest();

      setIsVisible(false);
      setTimeout(() => reloadItems(), 500);
    });
  }, [reloadItems]);

  /**************************************************************************************************/

  if (!isVisible) return null;

  if (showEditItem) {
    return (
      <EditPurchaseRequestItem
        data={data}
        setShowEditItem={setShowEditItem}
        glAccounts={glAccounts}
        taxTypes={taxTypes}
        reloadItems={reloadItems}
        isPosted={isPosted}
        isDisabled={isDisabled}
      />
    );
  }

  return (
    <tr>
      <td className="text-align-left">
        {data.description}
      </td>
      <td className="text-align-left">
        {data.model_number}
      </td>
      <td className="text-align-left">
        {data.gl_account?.label}
      </td>
      <td className="text-align-right">
        {formatMonetaryValue(data.unit_cost, {
          locale: data.currency_locale,
          code: data.currency_code,
        })}
      </td>
      <td className="text-align-center">
        {data.quantity}
      </td>
      {taxTypes?.length > 1 &&
        <>
          <td className="text-align-right">
            {formatMonetaryValue(data.pre_tax_amount, {
              locale: data.currency_locale,
              code: data.currency_code,
            })}
          </td>
          <td className="text-align-left">
            {data.tax_type?.label}
          </td>
          <td className="text-align-right">
            {formatMonetaryValue(data.tax_amount, {
              locale: data.currency_locale,
              code: data.currency_code,
            })}
          </td>
        </>
      }
      <td className="text-align-right">
        {formatMonetaryValue(data.total_cost, {
          locale: data.currency_locale,
          code: data.currency_code,
        })}
      </td>
      <td className="text-align-right">
        {data.notes}
      </td>
      {(!isDisabled || isPosted) && (
        <td className="text-align-left">
          <PurchaseRequestItemEditButton onClick={handleClickEditButton} />
          {!isDisabled && (
            <PurchaseRequestItemDeleteButton onClick={handleClickDeleteButton} />
          )}
        </td>
      )}
    </tr>
  );
}

export default React.memo(PurchaseRequestItem);
