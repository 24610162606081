/* eslint-disable no-invalid-this */
const { DATE_TIME_FORMAT } = require('../constants');

import React from 'react';
import ReactDOM from 'react-dom';
import DateOfBirth from '../components/system/web-forms/DateOfBirth.js';
import DatePicker from '../components/system/web-forms/DatePicker.js';
import FeederSchoolElement from '../components/system/web-forms/elements/FeederSchoolElement.js';
import { validateSelectedFacultyRelationship } from './faculty-staff-status.js';
import { handleNoSelectedRadio } from './radio.js';
import { toggleListener } from './toggle.js';

window.WebForm.Form = Ractive.extend({
  onconstruct: function (options) {
    options.append = true;
    options.el = '.vx-form';
  },

  onrender: function () {
    _.bindAllFunctions(this);

    let formFields = this.formInputNames(this.el);
    const emailFields = formFields.filter(function (name) {
      return /email/.test(name);
    });
    formFields = formFields.filter(function (name) {
      return !/email/.test(name);
    });

    this.set({
      button_label: $('.form-submit').text(),
      form_fields: _.map(formFields, this.createFormField),
      email_fields: _.map(emailFields, this.createFormField),
      form_warning: new WebForm.FormWarning({}),
    });

    // add event handlers for repeatable sections
    const that = this; // need access to current this within each loop
    $('.section-repeat-button').each(function () {
      const keypath = $(this).data('repeat-section');

      $(this).click({ section_keypath: keypath }, that.repeatSection);
    });

    // initialize jQuery plugins
    this.turnOnJqueryPlugins();

    // validate form on submission
    $(this.el).on('submit.validation', this.validateForm);
  },

  turnOnJqueryPlugins: function () {
    // initialize date pickers
    _.each($('.vx-date-field__input[data-control="true"]'), this.initDatePicker);

    // initialize time pickers
    $('.vx-time-field__input').pickatime();

    // initialize select2 dropdowns
    this.turnOnSelect2('.select2');
  },

  turnOnSelect2: function (selector) {
    $(selector).select2({
      dropdownAutoWidth: true,
      width: 'auto',
      templateResult: (state) => {
        const nodes = $.parseHTML('<span></span>');
        nodes[0].innerText = state.text;
        if (state.id >= 0) {
          nodes[0].dataset.optionValue = state.id;
        }
        return nodes;
      },
    });
  },

  turnOffJqueryPlugins: function () {
    $('.vx-date-field__input[data-control="true"]').each(function () {
      $(this).pickadate('stop');
    });
    $('.vx-time-field__input').each(function () {
      $(this).pickatime('stop');
    });
    $('.vx-select__input.select2').select2('destroy');
  },

  repeatSection: function (e) {
    e.preventDefault();

    const sectionKeypath = e.data.section_keypath;

    // make a copy of the section and keep track of the new fields
    const sectionInstances = document.querySelectorAll(
      `.section-container[data-keypath="${sectionKeypath}"]`
    );
    const lastSection = sectionInstances[sectionInstances.length - 1];

    // ex. inquiry_parents_address-327.0.name:first_name
    // becomes inquiry_parents_address-327.1.name:first_name
    function incrementKeypath(elementKeypath) {
      elementKeypath = elementKeypath.split('.');
      elementKeypath[1] = sectionInstances.length;
      return elementKeypath.join('.');
    }

    // add close button
    if (lastSection.querySelector('.repeat-close-button') === null) {
      const closeButton = document.createElement('button');
      closeButton.classList.add(
        'vx-button',
        'vx-button--small',
        'vx-button--icon',
        'vx-button--danger',
        'repeat-close-button'
      );

      const closeIcon = document.createElement('i');
      closeIcon.classList.add('nc-icon-glyph', 'ui-1_simple-remove');

      closeButton.appendChild(closeIcon);
      closeButton.addEventListener('click', this.deleteSection);

      lastSection.appendChild(closeButton);

      const heading = lastSection.querySelector('.vx-heading-2');
      const sectionNumber = document.createElement('span');
      sectionNumber.classList.add('candidate-number');
      sectionNumber.textContent = 1;
      heading.appendChild(sectionNumber);
    }

    // turn off jQuery plugins before copying
    this.turnOffJqueryPlugins();

    const copy = lastSection.cloneNode(true);

    const closeButton = copy.querySelector('.repeat-close-button');
    closeButton.addEventListener('click', this.deleteSection);

    const sectionNumber = copy.querySelector('.candidate-number');
    sectionNumber.textContent = sectionInstances.length + 1;

    const sectionFormFields = [];

    copy.querySelectorAll('input, textarea').forEach(function (field) {
      if (field.getAttribute('name')) {
        const fieldName = incrementKeypath(field.getAttribute('name'));
        field.setAttribute('name', fieldName);

        // hidden inputs created by empty radios don't have id
        const currentFieldId = field.getAttribute('id');
        if (currentFieldId) {
          field.setAttribute('id', incrementKeypath(currentFieldId));
        }

        if (field.getAttribute('data-hidden-default') === 'true') {
          // Intentionally do nothing. This is a preset value that should not be cleared and
          // should carry over to the new section.
        } else if (
          field.getAttribute('type') &&
          field.getAttribute('type').match(/^(radio|checkbox)$/)
        ) {
          field.checked = false;
        } else {
          field.value = '';
        }

        sectionFormFields.push(fieldName);
      }
    });

    copy.querySelectorAll('select').forEach(function (field) {
      if (field.getAttribute('name')) {
        const fieldName = incrementKeypath(field.getAttribute('name'));

        field.setAttribute('name', fieldName);
        field.selectedIndex = 0;

        sectionFormFields.push(fieldName);
      }
    });

    copy.querySelectorAll('label.vx-form-label').forEach(function (field) {
      if (field.getAttribute('for')) {
        field.setAttribute('for', incrementKeypath(field.getAttribute('for')));
      }
    });

    copy.querySelectorAll('[data-child-field-container]').forEach(function (field) {
      field.setAttribute(
        'data-child-field-container',
        incrementKeypath(field.getAttribute('data-child-field-container'))
      );
    });

    // create Date of Birth React components if copying section that had them
    copy
      .querySelectorAll('[data-react-class="system/web-forms/DateOfBirth"]')
      .forEach(function (field) {
        const reactProps = JSON.parse(field.dataset.reactProps);
        let keypath = reactProps['keypath'];
        keypath = incrementKeypath(keypath);
        ReactDOM.render(
          <DateOfBirth
            {...reactProps}
            keypath={keypath}
          />,
          field
        );

        sectionFormFields.push(keypath);
      });

    // create DatePicker React components
    copy.querySelectorAll('[data-react-class="system/web-forms/DatePicker"]').forEach((field) => {
      const reactProps = JSON.parse(field.dataset.reactProps);
      let keypath = reactProps['keypath'];
      keypath = incrementKeypath(keypath);
      ReactDOM.render(
        <DatePicker
          {...reactProps}
          keypath={keypath}
        />,
        field
      );

      sectionFormFields.push(keypath);
    });

    // create FeederSchoolElement React components
    copy
      .querySelectorAll('[data-react-class="system/web-forms/elements/FeederSchoolElement"]')
      .forEach((field) => {
        let keypath = JSON.parse(field.dataset.reactProps)['keypath'];
        const schoolOptions = JSON.parse(field.dataset.reactProps)['schoolOptions'];
        keypath = incrementKeypath(keypath);
        ReactDOM.render(
          <FeederSchoolElement
            keypath={keypath}
            schoolOptions={schoolOptions}
          />,
          field
        );

        sectionFormFields.push(keypath);
      });

    // Add listeners to newly created fields
    copy
      .querySelectorAll('[data-field-type="toggle"] input[type="checkbox"]')
      .forEach((toggleField) => {
        toggleField.addEventListener('change', (event) => toggleListener(event));
        toggleField.dispatchEvent(new Event('change'));
      });

    copy.querySelectorAll('[data-field-type="radio"] input[type="radio"]').forEach((radioField) => {
      radioField.addEventListener('change', (event) => handleNoSelectedRadio(event.target.name));
      radioField.dispatchEvent(new Event('change'));
    });

    copy.querySelectorAll('.vx-form-control').forEach(function (element) {
      if (element.dataset.fieldContainer) {
        element.dataset.fieldContainer = incrementKeypath(element.dataset.fieldContainer);
      }
    });

    // don't hide section if it was originally hidden
    copy.style.display = 'block';

    // add the copy to the DOM
    lastSection.after(copy);

    // reinitialize jQuery plugins to include new copy
    this.turnOnJqueryPlugins();

    // add the fields from the new section to this Ractive instance for validation
    const emailFields = sectionFormFields.filter(function (name) {
      return /email/.test(name);
    });
    const formFields = sectionFormFields.filter(function (name) {
      return !/email/.test(name);
    });

    this.set({
      form_fields: this.get('form_fields').concat(_.map(formFields, this.createFormField)),
      email_fields: this.get('email_fields').concat(_.map(emailFields, this.createFormField)),
    });

    // add another candidate to the repeatable relationship element
    this.repeatElement();

    // scroll to new section and focus first field
    copy.scrollIntoView({ behavior: 'smooth' });
    copy.querySelector('input, textarea, select').focus();
  },

  repeatElement: function () {
    // turn off select2 while copying
    $('.parent-relationships-repeatable .vx-select__input.select2').select2('destroy');

    const elements = document.querySelectorAll('.parent-relationships-repeatable');

    elements.forEach((element) => {
      const candidates = element.querySelectorAll('.parent-relationships-repeatable-candidate');

      const lastCandidate = candidates[candidates.length - 1];
      const copy = lastCandidate.cloneNode(true);

      const field = copy.querySelector('select');
      const formControl = copy.querySelector('.vx-form-control');

      let elementKeypath = field.getAttribute('name');
      elementKeypath = elementKeypath.split(':');
      const i = parseInt(elementKeypath[elementKeypath.length - 1]) + 1;
      elementKeypath[elementKeypath.length - 1] = i;
      elementKeypath = elementKeypath.join(':');

      field.setAttribute('name', elementKeypath);
      formControl.dataset.fieldContainer = elementKeypath;

      const tempName = copy.querySelector('.name');
      tempName.textContent = `${i18next.t('Candidate')} ${i + 1}`;

      lastCandidate.after(copy);

      // add to Ractive fields for validation
      this.push('form_fields', this.createFormField(elementKeypath));
    });

    // turn select2 back on
    this.turnOnSelect2('.parent-relationships-repeatable .vx-select__input.select2');
  },

  deleteSection: function (e) {
    e.preventDefault();

    // get the section of the close button that was clicked
    const section = e.srcElement.parentNode.closest('.section-container');
    const sectionKeypath = section.dataset.keypath;

    // remove the elements in the section from the Ractive representation of the form
    section.querySelectorAll('input, textarea, select').forEach((field) => {
      const element = $(field).closest('.vx-form-control').get(0);

      this.set(
        'form_fields',
        this.get('form_fields').filter((form_field) => form_field.el !== element)
      );
      this.set(
        'email_fields',
        this.get('email_fields').filter((email_field) => email_field.el !== element)
      );
    });

    // get which number candidate this is
    let relationshipNumber = section.querySelector('input');
    relationshipNumber = relationshipNumber.getAttribute('name');
    relationshipNumber = relationshipNumber.split('.');
    relationshipNumber = relationshipNumber[1];

    // remove the section from the DOM
    section.parentNode.removeChild(section);

    const elements = document.querySelectorAll('.parent-relationships-repeatable');

    elements.forEach((element) => {
      let candidates = element.querySelectorAll('.parent-relationships-repeatable-candidate');

      let relationshipKeypath = candidates[0].querySelector('select');
      relationshipKeypath = relationshipKeypath.getAttribute('name');
      relationshipKeypath = relationshipKeypath.split(':');
      relationshipKeypath[relationshipKeypath.length - 1] = relationshipNumber;
      relationshipKeypath = relationshipKeypath.join(':');

      let relationshipElementToRemove = document.querySelector(`[name="${relationshipKeypath}"]`);
      relationshipElementToRemove = relationshipElementToRemove.closest(
        '.parent-relationships-repeatable-candidate'
      );

      // remove the relationship field
      relationshipElementToRemove.parentNode.removeChild(relationshipElementToRemove);

      // refresh the keypaths
      candidates = element.querySelectorAll('.parent-relationships-repeatable-candidate');

      candidates.forEach(function (candidate, i) {
        const input = candidate.querySelector('select');
        let relationshipKeypath = input.getAttribute('name');

        relationshipKeypath = relationshipKeypath.split(':');
        relationshipKeypath[relationshipKeypath.length - 1] = i;
        relationshipKeypath = relationshipKeypath.join(':');

        input.setAttribute('name', relationshipKeypath);

        const tempName = candidate.querySelector('.name');
        tempName.textContent = `Candidate ${i + 1}`;
      });
    });

    const sectionInstances = document.querySelectorAll(
      `.section-container[data-keypath="${sectionKeypath}"]`
    );

    // if there are no repeats anymore then don't have a close button on the only section
    if (sectionInstances.length === 1) {
      const closeButton = sectionInstances[0].querySelector('.repeat-close-button');
      closeButton.parentNode.removeChild(closeButton);
    }

    function updateKeypath(keypath, i) {
      keypath = keypath.split('.');
      keypath[1] = i;
      return keypath.join('.');
    }

    // ensure the field keypaths are correct even if the repeat deleted isn't the last one
    sectionInstances.forEach(function (section, i) {
      section.querySelectorAll('input, textarea').forEach(function (field) {
        if (field.getAttribute('name')) {
          const fieldName = updateKeypath(field.getAttribute('name'), i);
          field.setAttribute('name', fieldName);

          // hidden inputs created by empty radios don't have id
          const currentFieldId = field.getAttribute('id');
          if (currentFieldId) {
            field.setAttribute('id', updateKeypath(currentFieldId, i));
          }
        }
      });

      section.querySelectorAll('select').forEach(function (field) {
        if (field.getAttribute('name')) {
          const fieldName = updateKeypath(field.getAttribute('name'), i);

          field.setAttribute('name', fieldName);
        }
      });

      section.querySelectorAll('label.vx-form-label').forEach(function (field) {
        if (field.getAttribute('for')) {
          field.setAttribute('for', updateKeypath(field.getAttribute('for'), i));
        }
      });

      const sectionNumber = section.querySelector('.candidate-number');

      if (sectionInstances.length > 1) {
        sectionNumber.textContent = i + 1;
      } else {
        sectionNumber.parentNode.removeChild(sectionNumber);
      }
    });
  },

  initDatePicker: function (dateField) {
    const years = $(dateField).data('select-years') ? $(dateField).data('select-years') : false;

    $(dateField).pickadate({
      format: 'mmmm d, yyyy',
      formatSubmit: 'yyyy-mm-dd',
      min: $(dateField).data('min'),
      max: $(dateField).data('max'),
      hiddenName: true,
      selectYears: years,
      selectMonths: true,
      onStart: function () {
        const parsedMoment = moment(dateField.dataset.value, DATE_TIME_FORMAT);
        if (parsedMoment.isValid()) {
          const parsedDate = parsedMoment.toDate();
          this.set('select', [
            parsedDate.getFullYear(),
            parsedDate.getMonth(),
            parsedDate.getDate(),
          ]);
        }
      },
    });

    // disable hidden input created by pickadate if
    // dateField comes as disabled
    if (dateField.disabled) {
      $(dateField.parentNode).find('input').prop('disabled', true);
    }
  },

  formInputNames: function (element) {
    return _.chain(validate.collectFormValues(element))
      .keys()
      .map(function (name) {
        return name.replace(/\\/g, '');
      }) // fix validate.js thrashing period delemited strings
      .value();
  },

  createFormField: function (field_name) {
    const input = $('[name="' + field_name + '"]');

    let validation_name = field_name.replace('[]', '');

    // if this field is a copy from a repeatable section you want the validations for the original keypath
    if (/^\S*\.{1}[1-9]\d*\.{1}\S*$/.test(field_name)) {
      validation_name = field_name.split('.');
      validation_name[1] = 0;
      validation_name = validation_name.join('.');
    }

    // repeatable element keypath matching excludes element number
    if (/^\S*\.parent_relationships_repeatable\S*\:[0-9]+/.test(field_name)) {
      validation_name = field_name.split(':');
      validation_name.pop();
      validation_name = validation_name[0];
    }
    // but use full keypath of first element for required lookup. Server required validation requires element number as part of keypath
    const requiredName = field_name.includes('repeatable')
      ? validation_name + ':0'
      : validation_name;

    // copy rules object so FormField can safely delete later without affecting
    // the Form level validations object
    const rules = Object.assign({}, this.get('validation_rules')[validation_name]);

    return new WebForm.FormField({
      el: input.closest('.vx-form-control').get(),
      data: {
        input: input,
        field_keypath: field_name,
        field_rules: rules,
        required: _.includes(this.get('required_fields'), requiredName),
        parent_form: this,
        is_email: /email/.test(field_name),
      },
    });
  },

  validateFields: function () {
    _.each(this.get('form_fields'), function (field) {
      if (field.get('field_keypath').includes(':employment_relationship')) {
        validateSelectedFacultyRelationship(field);
      } else {
        field.validate();
      }
    });
  },

  validateEmailFields: function () {
    _.each(this.get('email_fields'), function (field) {
      field.validate();
    });
  },

  validateAccountCreation: function () {
    return this.sendValidationRequest(window.location.pathname + '/account_validation.json');
  },

  validateEventSlotAvailable: function () {
    return this.sendValidationRequest(window.location.pathname + '/event_slot_validation.json');
  },

  sendValidationRequest: async function (endpoint) {
    const allFields = this.get('email_fields').concat(this.get('form_fields'));
    try {
      await $.ajax({
        url: endpoint,
        method: 'POST',
        data: $('form').serialize(),
      });
    } catch (data) {
      if (data.responseJSON.errors) {
        data.responseJSON.errors.forEach((error) => {
          error.keypaths.forEach((elementKeypath) => {
            const field = allFields.find((field) => field.get('field_keypath') === elementKeypath);
            let errors = field.get('errors');
            if (!errors) {
              errors = [];
            }
            errors.push(error.message);
            field.set('errors', errors);
          });
        });
      }
    }
  },

  validateParentCandidateRelationships: async function () {
    try {
      await $.ajax({
        url: window.location.pathname + '/parent_candidate_relationship_validation.json',
        method: 'POST',
        data: $('form').serialize(),
      });
      $('#relationships-validation-message')
        .closest('.element-container')
        .removeClass('vx-form-control--error');
      $('#relationships-validation-message').hide().html();
    } catch (data) {
      if (data.responseJSON.error) {
        const errorMessage = data.responseJSON.error.message;
        const relationshipContainer = $('#relationships-validation-message').closest(
          '.element-container'
        );
        relationshipContainer
          .addClass('vx-form-control--error')
          .children('#relationships-validation-message')
          .first()
          .html(errorMessage)
          .fadeIn('fast')
          .css('display', 'inline-block');
      }
    }
  },

  validateNewCandidateRelationships: async function () {
    try {
      await $.ajax({
        url: window.location.pathname + '/new_candidate_relationship_validation.json',
        method: 'POST',
        data: $('form').serialize(),
      });
      $('#relationships-validation-message')
        .closest('.element-container')
        .removeClass('vx-form-control--error');
      $('#relationships-validation-message').hide().html();
    } catch (data) {
      if (data.responseJSON.error) {
        const errorMessage = data.responseJSON.error.message;
        const relationshipContainer = $('#relationships-validation-message').closest(
          '.element-container'
        );
        relationshipContainer
          .addClass('vx-form-control--error')
          .children('#relationships-validation-message')
          .first()
          .html(errorMessage)
          .fadeIn('fast')
          .css('display', 'inline-block');
      }
    }
  },

  validateRelativesRelationships: async function () {
    try {
      await $.ajax({
        url: window.location.pathname + '/relatives_relationship_validation.json',
        method: 'POST',
        data: $('form').serialize(),
      });
      $('#relationships-validation-message')
        .closest('.element-container')
        .removeClass('vx-form-control--error');
      $('#relationships-validation-message').hide().html();
    } catch (data) {
      if (data.responseJSON.error) {
        const errorMessage = data.responseJSON.error.message;
        const relationshipContainer = $('#relationships-validation-message').closest(
          '.element-container'
        );
        relationshipContainer
          .addClass('vx-form-control--error')
          .children('#relationships-validation-message')
          .first()
          .html(errorMessage)
          .fadeIn('fast')
          .css('display', 'inline-block');
      }
    }
  },

  validateForm: async function (e) {
    e.preventDefault();
    // disable button to prevent double clicks
    $('.form-submit').prop('disabled', true);
    $('.form-submit').addClass('disabled loading').text('Submitting...');

    // check each form field for errors
    this.validateFields();
    this.validateEmailFields();
    if (this.get('form_type') === 'Parent') {
      await this.validateParentCandidateRelationships();
    }
    if (this.get('form_type') === 'New Registrant' || this.get('form_type') === 'Sibling') {
      await this.validateNewCandidateRelationships();
    }
    if (
      this.get('form_type') === 'Relatives' &&
      this.get('required_fields').some((field) =>
        /^relationship(s)?\.(\d+)?\.relationship(s)?$/.test(field)
      )
    ) {
      await this.validateRelativesRelationships();
    }

    const eventSignupField = _.find(this.get('form_fields'), (field) =>
      field.get('field_keypath').includes('event_signup')
    );
    if (eventSignupField && eventSignupField.currentValue().length) {
      await this.validateEventSlotAvailable();
    }

    if (!this.get('is_edit_preset')) {
      await this.validateAccountCreation();
    }

    if ($('.vx-form-control--error').length > 0) {
      $('body, html').animate(
        { scrollTop: $('.vx-form-control--error').first().offset().top - 45 },
        'fast'
      );
      this.resetButton();
    } else if (!this.get('is_edit_preset') && this.get('should_warn')) {
      this.checkForWarnings();
    } else if (this.get('recaptcha_enabled')) {
      $(this.el).off('submit.validation');
      grecaptcha.execute();
    } else {
      this.submitForm();
    }
  },

  // no-op right now. In the future would be responsible for
  // validating across multiple fields in a particular element
  validateElements: function () {},

  // re-enable the button and set back to original label
  resetButton: function () {
    $(this.el).find('.form-submit').removeClass('disabled loading').text(this.get('button_label'));
    $(this.el).find('.form-submit').prop('disabled', false);
  },

  // everything is good, submit our form to the database
  submitForm: function () {
    $(this.el).off('submit.validation').submit();
  },

  checkForWarnings: function () {
    $.ajax({
      url: window.location.pathname + '/warnings.json',
      method: 'POST',
      data: $('form').serialize(),
      success: this.processWarnings,
      error: this.warningCheckFailure,
    });
  },

  processWarnings: function (data) {
    if (data.warnings && data.warnings.length) {
      this.get('form_warning').set('warnings', data.warnings);
      this.resetButton();
    } else if (this.get('recaptcha_enabled')) {
      $(this.el).off('submit.validation');
      grecaptcha.execute();
    } else {
      this.submitForm();
    }
  },

  warningCheckFailure: function (jqXHR, textStatus, errorThrown) {
    this.resetButton();
    Swal.fire(
      'Something went wrong',
      'There was an error when trying to submit the form. Please try again.',
      'error'
    );
  },
});
